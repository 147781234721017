<template>
	<div class="loader-wrapper">
		<div class="inner-wrapper">
			<circle-progress
				:percent="progress"
				:is-bg-shadow="false"
				:show-percent="true"
				:size="120"
				class='percentage'
				empty-color="#ffffff"
				fill-color="#009371"
				:border-width="7"
				:border-bg-width="7"
			>
			</circle-progress>
			<div class="msg">
				Ju lutem prisni derisa te ngarkohen te dhenat!
			</div>

		</div>
	</div>
</template>
<script>
import "vue3-circle-progress/dist/circle-progress.css"
import CircleProgress from "vue3-circle-progress"

export default {

	components: {
		name: 'loader',
		CircleProgress
	},
	props: {
		progress: {
			type: Number,
			default: 0
		},
	},
	data() {
		return {
		};
	},
}
</script>
	
<style scoped>
.loader-wrapper{
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 22;
	position: fixed;
	background-color: rgba(44, 62, 80, 0.4);
}
.loader-wrapper .inner-wrapper{
	position: relative;
	top: 50%;
	max-width: 300px;
	height: auto;
	width: 100%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.percentage{
	left: 30%;
}
.d-flex{
	display: flex;
	justify-content: space-between;
}
.msg{
	position: relative !important;
	width: 100% !important;
	text-align: center !important;
	color: #fff !important;
	margin-top: 30px !important;
	font-size: 20px;
}

.current-counter * {
    font-size: 30px!important;
    color: #fff!important;
}
.current-counter *:after{
	content: "%"!important;
}

</style>