<template>
	<div class="example-simple">
		<h3 id="example-title" class="example-title">Ngarko foto</h3>
		<div class="upload">
		<div class="custom-file">
			<file-upload
				extensions="gif,jpg,jpeg,png,webp"
				accept="image/png,image/gif,image/jpeg,image/webp"
				:multiple="true"
				:maximum="10"
				:size="1024 * 1024 * 10"
				:quality="0.2"
				v-model="files"
				@input-filter="inputFilter"
				@input-file="inputFile"
				ref="upload"
				name="place_image[]"
				post-action="https://devappishp.altirana.com/index.php"
			>
			</file-upload>
		</div>
		<ul class="files-list">
			<li v-for="file in files" :key="file.id">
				<span class="remove-item" @click.prevent="remove(file)" title="Remove Item"></span>
				<img class="td-image-thumb" v-if="file.thumb" :src="file.thumb" />
			</li>
		</ul>
		</div>
	</div>
</template>

<script>
import {ref} from 'vue'
import FileUpload from 'vue-upload-component'
import Compressor from 'compressorjs';

export default {
  components: {
    FileUpload
  },
  setup() {
		const upload = ref(null)
		
		const files = ref([])
		function inputFilter(newFile, oldFile, prevent) {
			
			if (newFile && !oldFile) {
				// Before adding a file
				// Filter system files or hide files
				if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
					return prevent()
				}
				// Filter php html js file
				if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
					return prevent();
				}
				// Automatic compression, 
				if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
					new Compressor(newFile.file, {
						quality: 0.9,
						
						success: (result) => {
							newFile.file = new File([result], result.name, {
								type: result.type,
								lastModified: new Date()
							}); 
							newFile.size = result.size
							newFile.type = result.type
							newFile.blob = result
							console.log(result)
						},
						error: (e) => {
							newFile.error = e.message
						},
					});

					// Create a blob field
					newFile.blob = ''
					let URL = (window.URL || window.webkitURL)
					if (URL) {
						newFile.blob = URL.createObjectURL(newFile.file)
					}
					// Thumbnails
					newFile.thumb = ''
					if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
						newFile.thumb = newFile.blob
					}
				}
			}

			
    	}
		function inputFile(newFile, oldFile) {
			if (newFile && oldFile) {
					


			// Uploaded successfully
				if (newFile.success !== oldFile.success) {
					if(newFile.success) {
						upload.value.clear();
					}
				}
			}
		}
		
		return {
			files,
			upload,
			inputFilter,
			inputFile,
		}
  	},
	data(){
		return {
			autoCompress: 1024 * 1024,
		}
	},
  	methods: {
		remove(file) {
			this.$refs.upload.remove(file)
		},
		formatNumber (num) {
			return parseFloat(num).toFixed(0)
		}

		
  	}
}
</script>

<style>
.example-simple label.btn {
	margin-bottom: 0;
	margin-right: 1rem;
}
.upload ul{
	padding-left: 0px !important;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -5px 20px;
}
.upload li{
	padding: 5px;
	font-weight: bold;
	font-style: italic;
	margin-bottom: 15px;
	margin: 0 5px 10px;
	background: #f3f3f3;
	border: 1px solid #d3d3d3;
	border-radius: 4px;
	width: 60px;
	height: 60px;
	position: relative;
	display: flex;
	justify-content: center;
}
.upload li img{
	max-height: 60px;
	max-width: 60px;
	height: auto;
	width: auto;
}
.remove-item{
	display: inline-block;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    color: #000000;
    cursor: pointer;
    margin-left: 6px;
    text-shadow: 0 1px 0 #fff;
    position: absolute;
    top: -10px;
	right: -7px;
    font-style: normal;
    /* transform: scale(1, 0.8); */
}
.remove-item:after{
	display: inline-block;
	content: "\00d7";
}
.file-uploads{width: 100%;}
.file-uploads.file-uploads-html5 input{
  opacity: 0!important;
  position: static!important;
  display: inline-block!important;
  width: 100%!important;
  height: 2.75rem!important;
  padding: 0.5rem 1.875rem 0.5rem 0.875rem!important;
  font-size: .8125rem!important;
  font-weight: 400!important;
  line-height: 1.47!important;
  color: #495057!important;
  vertical-align: middle!important;
}
.file-uploads.file-uploads-html5 label{position: relative!important;opacity: 1!important;font-size: .8125rem!important;width: 100%!important;}
.file-uploads.file-uploads-html5 label:after {
    position: absolute!important; 
    top: 0!important;
    right: 0!important;
    bottom: 0!important;
    z-index: 3!important;
    display: flex!important;
    height: 2.75rem!important;
    padding: 0.5rem 0.875rem!important;
    line-height: 1.58!important;
    color: #495057!important;
    align-items: center!important;
    content: "Kliko këtu për të ngarkuar foto(t).";
    background-color: #f3f3f3!important;
    border-left: inherit!important;
    border-radius: 4px!important;
    text-align: center!important;
    justify-content: center!important;
    cursor: pointer!important;
    align-self: center!important;
    font-weight: normal!important;
    width: 100%!important;
    border: 1px dashed #495057!important;
}
</style>