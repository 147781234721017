<template>
  <loader v-if="showLoader" :progress="percentCompleted" />
    <h2 class="text-center">Raporto Ngjarjen</h2>

  <form
        id="id_skeda"
        name="skeda"
        enctype="multipart/form-data"
        method="post"
        action="javascript:void(0)"
        @submit="handleSubmit"
    >
    <Alertmsg v-if="showAlert" :success="isSubmitSuccess" />
    <div class="form-control" :class="{ invalid: !emri.isValid }">
        <label for="emri"> *Emri juaj:</label>
        <input
            id="emri"
            type="text"
            v-model.trim="emri.val"
            @input="clearValidity('emri')"
        />
        <p v-if="!emri.isValid">Ju lutemi vendosni emrin!</p>
    </div>

    <div class="form-control" :class="{ invalid: !telefoni.isValid }">
        <label for="telefoni"> *Telefoni:</label>
        <input
            id="telefoni"
            type="number"
            v-model="telefoni.val"
            @input="clearValidity('telefoni')"
        />
        <p v-if="!telefoni.isValid">Numri i vendosur nuk është i saktë</p>
    </div>

    <div class="form-control" :class="{ invalid: !ngjarja.isValid }">
      <label for="ngjarja"> *Përshkrimi i ngjarjes:</label>
        <textarea
        id="ngjarja"
        rows="5"
        type="text"
        v-model.trim="ngjarja.val"
        @input="clearValidity('ngjarja')"
        >
        </textarea>
        <p v-if="!ngjarja.isValid">Ju lutemi përshkruani ngjarjen!</p>
    </div>

    <div class="form-control" :class="{ invalid: !bashkia.isValid }">
       <label for="bashkia">*Zgjidhni bashkinë</label>
		<VueMultiselect
            @select="clearValidity('bashkia')"
			v-model="bashkia.val"
			:options = "bashkia.bashkiaList"
			:close-on-select="true"
			:clear-on-select="false"
			placeholder=""
			label="name"
			track-by="id_municipality"
            :allow-empty="false"
            :show-labels="false"
		>
            <template v-slot:noResult>Kërkimi juaj nuk dha rezultate!</template>
        </VueMultiselect>
         <p v-if="!bashkia.isValid">Ju lutemi zgjidhni bashkinë</p>
    </div>
    <div class="form-control" >
        <label for="fshati">Zgjidhni fshatin</label>
		<VueMultiselect
			v-model="fshati.val"
			:options = "fshatratList"
			:close-on-select="true"
			:clear-on-select="false"
			placeholder=""
			label="name"
			track-by="id_village"
            :allow-empty="false"
            :show-labels="false"
            :disabled="!bashkia.val"
		>
            <template v-slot:noResult>Kërkimi juaj nuk dha rezultate!</template>
        </VueMultiselect>
    </div>
    <div class="input-group">
      <label for="place_adress"> Adresa:</label>
      <textarea id="place_adress" rows="1" type="text" v-model="place_adress">
      </textarea>
      <div class="input-group-append">
        <img class="" :src="mapMarker" @click="showmap = true" />
        <p>
          <em
            >Klikoni ikonën për të plotësuar adresën automatikisht ose
            plotësojeni manualisht.</em
          >
        </p>
      </div>
    </div>
    <div class="imageUpload">
      <img-upload ref="imageUpload"></img-upload>
    </div>

    <Map
      v-if="showmap"
      @close-map="showmap = false"
      @return-data="setMapdata"
    />
    <div>
      <button>Regjistro ngjarjen</button>
    </div>
  </form>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.css"
import ImgUpload from "./ImgUpload.vue";
import Map from "./Map.vue";
import loader from "./loader.vue";
import Alertmsg from "./Alert.vue";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LTooltip,
  LPopup,
  LPolyline,
  LPolygon,
  LRectangle,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";

let bashkitejson = require("../json/bashkite.json");
let fshatijson = require("../json/fshati.json");

export default {
  components: {
    ImgUpload,
    Map,
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LTooltip,
    LPopup,
    LPolyline,
    LPolygon,
    LRectangle,
    loader,
    Alertmsg,
	VueMultiselect,
  },
  data() {
    return {
      percentCompleted: 0,
      showmap: false,
      zoom: 7,
      iconWidth: 25,
      iconHeight: 40,
      center: [41.328274, 19.818731],

      emri: {
        val: "",
        isValid: true,
      },
      telefoni: {
        val: "",
        isValid: true,
      },
      ngjarja: {
        val: "",
        isValid: true,
      },
      bashkia: {
        bashkiaList: bashkitejson,
        val: null,
        isValid: true,
      },
      fshati: {
        fshatiList: fshatijson,
        val: "",
      },
      place_adress: "",
      formIsValid: true,
      mapMarker: require("../assets/img/location-icon.svg"),
      inputDisabled: true,
      showLoader: false,
      showAlert: false,
      isSubmitSuccess: true,
      postURL: process.env.VUE_APP_POST_URL,
      getGeoCodingURL: process.env.VUE_APP_GEOCODING_URL,

    };
  },
  computed: {
    iconUrl() {
      return `../assets/img/marker.png`;
    },
    iconSize() {
      return [this.iconWidth, this.iconHeight];
    },
    fshatratList() {
      let lista = [];
      if (this.bashkia.val) {
        lista = this.fshati.fshatiList.filter(function (fshat) {
          return this.bashkia.val.id_municipality == fshat.id_municipality;
        }, this);
      }
      return lista;
    },
  },
  created() {
    this.getLocalStorage();
  },
  methods: {
      clearFormAfterSubmit() {
        (this.fshati.val = ""), (this.ngjarja.val = ""), (this.place_adress = "");
        this.$refs.imageUpload.files = [];
      },
    setMapdata(mapdata) {
      if (mapdata.center) {
        this.center = mapdata.center;
      }
      this.showmap = false;

      let coordinates = "&latlng=" + this.center.lat + "," + this.center.lng;
      axios
        .get(
          this.getGeoCodingURL + coordinates
        )
        .then((response) => {
          if (
            response.data &&
            response.data.results[0] &&
            response.data.results[0].formatted_address
          ) {
            this.place_adress = response.data.results[0].formatted_address;
          } else if (
            response.data &&
            response.data.results[0] &&
            response.data.results[0].address_components &&
            response.data.results[0].address_components[0] &&
            response.data.results[0].address_components[0].long_name
          ) {
            this.place_adress =
              response.data.results[0].address_components[0].long_name;
          }
        });
    },
    changeIcon() {
      this.iconWidth += 2;
      if (this.iconWidth > this.iconHeight) {
        this.iconWidth = Math.floor(this.iconHeight / 2);
      }
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      if (this.emri.val === "") {
        this.emri.isValid = false;
        this.formIsValid = false;
      }

      if (this.telefoni.val === "") {
        this.telefoni.isValid = false;
        this.formIsValid = false;
      }

      if (this.ngjarja.val === "") {
        this.ngjarja.isValid = false;
        this.formIsValid = false;
      }

      if (this.bashkia.val === null) {
        this.bashkia.isValid = false;
        this.formIsValid = false;
      }
    },
    handleSubmit() {
      this.validateForm();
      if (!this.formIsValid) {
        return;
      } else {
        this.submitData();
        
      }
    },
    submitData() {
      let $this =this
      let formdata = new FormData();
      this.$refs.imageUpload.files.forEach((file) => {
        formdata.append("place_image[]", file.file);
      });
      formdata.append("reporter_name", this.emri.val);
      formdata.append("reporter_tel", this.telefoni.val);
      formdata.append("description", this.ngjarja.val);
      formdata.append("place_id_municipality", this.bashkia.val.id_municipality);
      formdata.append("place_id_village", this.fshati.val.id_village);
      formdata.append("place_address", this.place_adress);
      formdata.append("latitude", this.center.lat);
      formdata.append("longitude", this.center.lng);
      this.setLocalStorage();
      const hconfig = {
        headers: {
          //'content-type': 'application/json',
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function(progressEvent) {
          $this.percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          console.log($this.percentCompleted)
        }
      };
      this.showLoader = true
      // axios call here]
      axios
        .post(this.postURL, formdata, hconfig )
        .then((response) => {
          (this.showLoader = false), (this.showAlert = true);
          this.isSubmitSuccess = true;
          setTimeout(() => (this.showAlert = false), 4000);
          this.clearFormAfterSubmit();
          window.scrollTo(0, 0);
          $this.percentCompleted = 0
        })
        .catch((error) => {
          (this.showLoader = false), (this.showAlert = true);
          this.isSubmitSuccess = false;
          setTimeout(() => (this.showAlert = false), 4000);
        });
    },



    setLocalStorage() {
      let formdata = {
        emri: this.emri.val,
        telefoni: this.telefoni.val,
        bashkia: this.bashkia.val.name,
      };
      localStorage.setItem("formdefault", JSON.stringify(formdata));
    },
    getLocalStorage() {
      let found = localStorage.getItem("formdefault");
      if (found) {
        found = JSON.parse(found);
        Object.keys(found).forEach((key) => {
          if (this[key]) {
            this[key].val = found[key];
          }
        });
      }
    },
  },
};
</script>
<style src="../assets/stylesheet/ishp_form.css"></style>
