<template>
    <div
        class="alert alert-dismissible fade"
        :class=" success == true ? 'alert-success' : 'alert-error' "
    >
        <button type="button" @click="handleClose" ref="Close" class="close" data-dismiss="alert" aria-label="Close">
        </button>
        <div class="d-flex align-items-center">
            <div class="flex-1"  v-if="success == true">
				Ngjarja u regjistrua me <strong> sukses</strong> dhe Shërbimi i Epidemiologjisë u njoftua.<br> Ju Faleminderit.
            </div>
            <div class="flex-1"  v-if="success != true">
				<div><strong>Sistemi hasi një problem!</strong></div>
				Ngjarja nuk u regjistrua. Ju lutemi provoni perseri.
            </div>

        </div>
    </div>

</template>
<script>
    export default {
        name: 'Alertmsg',
        props:{
            show: {
                type: Boolean,
                default: false
            },
            success: {
                type: Boolean,
                default: true
            }
        },
		data() {
			return{
				isVisibleAlert: this.show
			}
		},
		methods: {
			handleClose(){
				this.$parent.showAlert = false
			}
		}
    }
</script>
<style scoped>
.alert {
    position: relative;
    padding: 1rem 1.25rem;
    margin-bottom: 2rem;
    border: 1px solid transparent;
    border-radius: 4px;
    z-index: 22;
    margin: 0 auto 2rem;
    max-width: 520px;
    opacity: 0;
    transform: translateY(-60px);

}

.h3{
    font-size: 1.1875rem;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1;
    display: block;    
}

.close{
    display: inline-block;
	font-size: 20px;
	font-weight: bold;
	color: #ffffff;
	cursor: pointer;
	text-shadow: 0 0 black;
	position: relative;
	font-style: normal;
	text-decoration: none;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
    opacity: .5;
}
.close:after{
	display: inline-block;
	content: "\00d7";
}



button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
.fade {
    opacity: 1;
    transition: all 0.3s ease;
    transform: translateY(0);


}
.alert-dismissible {
    padding-right: 3.71875rem;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    color: inherit;
}
.alert-success {
    color: #45a197;
    background-color: #f7fdfc;
    border-color: #a3ebe4;
}
.alert-error {
    color: #fd2062;
    background-color: #ffe5f1;
    border-color: #fd2062;
}

</style>