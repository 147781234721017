<template>
	<div class="map-wrapper">
		<div class="title">
			<div>Zgjidhni ne harte</div>
			<a class="close" href="javascript:void(0)" @click="close"></a>
		</div>
		<div class="body">
			<l-map @ready="onReady" @locationfound="onLocationFound"
				v-model="zoom"
				v-model:zoom="zoom"
				v-model:center="center"
				@move="log(center)"
				ref="map"
			>
				<l-tile-layer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				></l-tile-layer>
				<template v-if="location">
					<l-circle-marker :lat-lng="location.latlng" :fillOpacity="1" :fill="true" :radius="6" color="#009371" fill-color="#009371" />
					<l-circle-marker :lat-lng="location.latlng" :fillOpacity="0.3" :fill="true" :radius="location.accuracy" :stroke="false" color="#009371" fill-color="#009371" />
				</template>
				<div class="map-marker">
					<img class="staycenter-marker-icon" src="../assets/img/marker.jpg" />
				</div>
			</l-map>
		</div>
	  <div class="footer">
		  	<div>
				<button type="button" @click="returnData">Ruaj</button>  
		  	</div>
			<div class="hint">
				Poziciono ikonën në vendgjarje dhe shtyp butonin ruaj.
			</div>
	  </div>
    </div>
</template>

<script>
import {
  LMap,
  LIcon,
  LCircle,
  LCircleMarker,
  LTileLayer,
  LMarker,
  LControlLayers,
  LTooltip,
  LPopup,
  LPolyline,
  LPolygon,
  LRectangle,
} from "@vue-leaflet/vue-leaflet";

import "leaflet/dist/leaflet.css";
export default {
	name: 'Map',
	components: {
		LMap,
		LIcon,
		LCircle,
		LCircleMarker,
		LTileLayer,
		LMarker,
		LControlLayers,
		LTooltip,
		LPopup,
		LPolyline,
		LPolygon,
		LRectangle,
	},
	data(){
		return {
			zoom: 13,
			iconWidth: 25,
			iconHeight: 40,
			center: [41.32842, 19.818048],
			location: null
		}
	},
	methods: {
		returnData(){
			let data = {
				center: this.center
			}
			this.$emit('returnData', data)
		},
		close(){
			this.$emit('closeMap')
		},
		log(a) {
			//console.log(a);
		},
		changeIcon() {
			this.iconWidth += 2;
			if (this.iconWidth > this.iconHeight) {
				this.iconWidth = Math.floor(this.iconHeight / 2);
			}
		},
		onReady(themap) {
			this.map = themap;
			this.map.locate();
		},
		onLocationFound(l) {
			console.log(l);
			this.location = l;
			this.center = l.latlng;
		},
	}
}
</script>

<style lang="css">
.map-wrapper{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: white;
	z-index: 10;
	display: flex;
	flex-direction: column;
}
.map-wrapper .title{
	padding: 15px;
	background-color: #009371;
	color: white;
	font-size: 1rem;
	font-weight: 700;
	border-bottom: 1px solid #009371;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.map-wrapper .title .close{
	display: inline-block;
	font-size: 20px;
	font-weight: bold;
	color: #ffffff;
	cursor: pointer;
	text-shadow: 0 0 black;
	position: relative;
	font-style: normal;
	text-decoration: none;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
}
.map-wrapper .title .close:after{
	display: inline-block;
	content: "\00d7";
}
.map-wrapper .title a{
	color: white;
	text-decoration: none;
}
.map-wrapper .body{
	flex-grow: 1;
}
.map-wrapper .footer{
	padding: 15px;
	display: flex;
	align-items: center;
}
.map-wrapper .footer .hint{
	padding-left: 15px;
	line-height: 1.2;
}
.map-wrapper .footer button{
	margin: 0;
}

.staycenter-marker-icon {
    position: absolute !important;
    top: calc(50% - 20px) !important;
    left: 50% !important;
	transform: translate(-50%, -50%);
	margin-bottom: 10px;
    display: block !important;
    background-color: transparent;
    z-index: 999 !important;
	background-size: cover;
    background-image: url("../assets/img/marker.jpg");
    width: 50px !important;
    height: 50px !important;
    margin: 0 !important;
    content: "";
}
</style>