<template>
    <div class="header">
    <div class="logo">

        <a href="#">
        <img class="img-fluid" src="./assets/img/logo.png" alt="Instituti i Shëndetit publik">
        </a>

    </div>
      <h1 class="text-center text-primary">Sistemi i Informacionit të Sëmundjeve Infektive</h1>

    </div>
    <ISHP_Form />


    <div class="footerArea">
        <div class="copyright">
            <p>© 2022. Instituti i Shëndetit Publik - SISI</p>
        </div>
    </div>
</template>

<script>

import 'normalize.css'
import style from "./assets/stylesheet/App.css";
import addToHomeBtn from './components/addToHomeBtn.vue'
import ISHP_Form from './components/ISHP_Form.vue'
export default {
  name: 'App',
  components: { ISHP_Form, addToHomeBtn },  
}
</script>
